@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.goog-te-combo {
    @apply bg-white text-gray-700 border border-gray-300 rounded-lg px-3 py-2 shadow-md cursor-pointer;
    font-family: 'Arial', sans-serif !important;
    font-size: 1rem !important;
  }
  
  .goog-te-combo:hover {
    @apply border-blue-500;
  }


.ql-container .ql-editor table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ql-container .ql-editor table,
  .ql-container .ql-editor th,
  .ql-container .ql-editor td {
    border: 1px solid #ddd;
    padding: 8px;
  }

.main-container {
    @apply bg-gray-100 p-4 relative min-h-screen;
    padding-bottom: 60px;
}

.container {
    @apply w-full md:w-4/6 p-1 bg-white my-2 rounded shadow-lg;
}

.qa-container {
    @apply mb-6;
}

.react-question {
    @apply font-sans font-semibold text-xl my-2;
}

.react-answer {
    @apply text-lg text-gray-800;
}

.code-container {
    @apply overflow-x-auto bg-gray-200 p-2 rounded my-2;
}

.breadcrumb {
    @apply text-orange-500 mb-4;
}

.breadcrumb-link {
    @apply text-orange-500 cursor-pointer;
}

.breadcrumb-current {
    @apply text-gray-400;
}
p{
    padding: 2px;
}
.pagination {
    @apply flex justify-center items-center space-x-4 mt-6 p-3 bg-white shadow-lg rounded;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
}

.pagination button {
    @apply bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded;
}

.pagination span {
    @apply text-gray-800 font-bold;
}



.custom-prev-arrow,
.custom-next-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000; /* Background color */
    border-radius: 50%;
    padding: 10px;
    color: #fff; /* Icon color */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.custom-prev-arrow {
    left: -25px; /* Positioning for left arrow */
}

.custom-next-arrow {
    right: -25px; /* Positioning for right arrow */
}



.list-disc-with-arrow li::before {
    content: '→'; /* Or use any other arrow symbol or icon */
    color: #4A5568; /* Tailwind text-gray-700 */
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
    /* Adjust size and color as needed */
  }



  .slick-slide {
    margin-right: 15px; /* Add space between the slides */
  }
  
  .slick-list {
    margin-left: -15px; /* Adjust left margin to align the first slide */
  }